type RippleButtonComponentProps = {
  children: any;
  extraClass?: string;
  handleClick?: (e: any) => void;
};

const RippleButton = ({
  children,
  extraClass,
  handleClick,
}: RippleButtonComponentProps) => {
  return (
    <button
      onClick={(e: any) => handleClick && handleClick(e)}
      className={`block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium text-white bg-black hover:bg-gray-900 transition duration-300 ease-in-out transform hover:scale-105 ${extraClass}`}
    >
      {children}
    </button>
  );
};

export default RippleButton;
