import RippleButton from "../ripple-button/ripple-button.component";

type PricingTryNowButtonComponentProps = {
  children: any;
  extraWrapperClass?: string;
  buttonClass?: string;
  handleClick?: (e: any) => void;
};

const PricingTryNowBtnComponent = ({
  children,
  extraWrapperClass,
  buttonClass,
  handleClick,
}: PricingTryNowButtonComponentProps) => {
  return (
    <div className={`px-6 pt-6 pb-8 sm:p-10 sm:pt-6 ${extraWrapperClass}`}>
      <RippleButton extraClass={buttonClass} handleClick={handleClick}>
        {children}
      </RippleButton>
    </div>
  );
};

export default PricingTryNowBtnComponent;
