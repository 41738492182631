export type HandleSubmitProps = {
  price: string;
  mode: string;
  discount?: string;
  trackEvent?: () => any;
};

const createInput = (name: string, value: string) => {
  const input = document.createElement("input");
  input.type = "hidden";
  input.name = name;
  input.value = value;
  return input;
};

const handleCreatePriceSession = ({
  price,
  mode,
  discount,
  trackEvent,
}: HandleSubmitProps) => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = `${process.env.REACT_APP_APPLICATION_URL}/session-checkout`;

  const modeInput = createInput("mode", mode);
  const priceTagInput = createInput("priceTag", price);
  const currentPromo = discount ? createInput("reduction", discount) : null;

  form.appendChild(modeInput);
  form.appendChild(priceTagInput);
  currentPromo && form.appendChild(currentPromo);

  document.body.appendChild(form);
  form.submit();
  trackEvent && trackEvent();
};

export default handleCreatePriceSession;
